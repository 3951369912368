<template>
  <div>
    <div
      class="d-flex flex-wrap align-center justify-space-between rounded mb-4"
      style="gap: 0.5rem"
      :class="{
        'white': !$vuetify.theme.dark,
        'grey darken-4': $vuetify.theme.dark,
      }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="{
          'primary white--text': $route.name == item.route,
        }"
        class="py-2 px-4 rounded pointer text-center col-auto col-auto"
        @click="goToSection(item)"
      >
        {{ item.title }}
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      items: [
        { title: this.$t('Info'), route: 'view-factory.info' },
        { title: this.$t('Business Symbol'), route: 'view-factory.business-symbol' },
        { title: this.$t('Accounting'), route: 'view-factory.accounting' },
        { title: this.$t('Attachments'), route: 'view-factory.attachment' },
        { title: this.$t('Residence'), route: 'view-factory.residence' },
        { title: this.$t('Social Security'), route: 'view-factory.social-security', notifyKey: 'social_security' },
      ],
      viewData: {},
    }
  },

  mounted() {
    this.getViewData().then(() => {
      this.$_section_title({ title: this.viewData.name_ckb })
    })
  },

  methods: {
    goToSection(item) {
      this.$router.push({ name: item.route })
    },

    async getViewData() {
      await axios.get(`factory/factory/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>